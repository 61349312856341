import axios from '../axios'
import BaseClass from './BaseClass'

export default class ML_Publication extends BaseClass {

    //Campos
    id
    idMercado
    productId
    tittle
    soldQuantity
    link
    status
    validations
    jsonML

    constructor() {
        super('api/mercadolibre/publications/')
    }

    async validate(id) {
        let body = {
            productId: id,
            images: []
        }
        const res = await axios.post(`${this.urlBase}validate`, body)
        return res
    }

    async validatePublication(id) {
        const res = await axios.get(`${this.urlBase}${id}/validate`)
        return res
    }

    async publish(id) {
        const res = await axios.get(`${this.urlBase}${id}/publish`)
        return res
    }
    
    async import(id) {
        const res = await axios.post(`${this.urlBase}import?id=${id}`)
        return res
    }

    async pause(id) {
        const res = await axios.get(`${this.urlBase}${id}/pause`)
        return res
    }

    async resume(id) {
        const res = await axios.get(`${this.urlBase}${id}/resume`)
        return res
    }

    async close(id) {
        const res = await axios.get(`${this.urlBase}${id}/close`)
        return res
    }

    async importAllByUserId(id) {
        const res = await axios.get(`${this.urlBase}importAll/${id}`)
        return res
    }

    async delete(id) {
        const res = await axios.delete(`${this.urlBase}${id}`)
        return res
    }

    async edit(id, body) {
        const res = await axios.put(`${this.urlBase}${id}`, body)
        return res
    }

    async validateMultiple(ids) {
        let body = ids.map(id => {return {productId: id}})
        const res = await axios.post(`${this.urlBase}validate_multiple`, body)
        return res
    }

    async validateExistentMultiple(ids) {
        let body = ids.map(id => {return {publicationId: id}})
        const res = await axios.post(`${this.urlBase}validate_existent_multiple`, body)
        return res
    }

    async publishMultiple(ids) {
        let body = ids.map(id => {return {publicationId: id}})
        const res = await axios.post(`${this.urlBase}publish_multiple`, body)
        return res
    }

    async pauseMultiple(ids) {
        let body = ids.map(id => {return {publicationId: id}})
        const res = await axios.post(`${this.urlBase}pause_multiple`, body)
        return res
    }

    async resumeMultiple(ids) {
        let body = ids.map(id => {return {publicationId: id}})
        const res = await axios.post(`${this.urlBase}resume_multiple`, body)
        return res
    }

    async deleteMultipleDB(ids) {
        let body = ids.map(id => {return {publicationId: id}})
        const res = await axios.post(`${this.urlBase}delete_multiple`, body)
        return res
    }

    async deleteMultipleML(ids) {
        let body = ids.map(id => {return {publicationId: id}})
        const res = await axios.post(`${this.urlBase}delete_multiple_mercadolibre`, body)
        return res
    }

}